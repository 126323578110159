import {
  faExclamationTriangle,
  faTicket,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ErrorDisplay,
  ValidationErrorDisplay,
} from "components/Shared/APIMetaDataDisplay";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import { useSessionHandler } from "hooks/useSessionHandler";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Session_Reducer_PushTransaction } from "rootExports/SessionReducer";
import {
  BookingItemModel,
  UpdatedBookingApiResponse,
  ENotificationActionRequiredEnumModel,
  GetPNRApiResponse,
} from "WebApiClient";
import { BookingMangerTableHeader } from "./BookingManager.Table";
import { BookingMangerTableRowData } from "./BookingManager.Table.Row";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { BookingMangerPassengersTableData } from "components/Shared/Helpers/BookingManagement/BookingPassengerHelpers";
import { BookingMangerSegmentsTableData } from "components/Shared/Helpers/BookingManagement/BookingSegmentsHelpers";
import { GetBookingStatusDisplay } from "./BookingStatusLabel";
import _ from "lodash";
import moment from "moment";
import BookingManagerManageNotificationModal from "./BookingManager.ManageNotificationsModal";
import { Card, CardContent, Stack } from "@mui/material";

import { useBootstrap5MediaQuery } from "hooks/useMediaQuery";
import ConfirmDetailSendModal from "./ConfirmDetailsendModal";

// material new table
interface ConfirmTicketModalProps {
  Booking: BookingItemModel;
  OnUpdate: (data: BookingItemModel) => void;
  Toggle: () => void;
  IsOpen: boolean;
  OnSync?: () => void;
}

const ConfirmTicketModal: React.FC<ConfirmTicketModalProps> = (props) => {


  return (
    <React.Fragment>
      <Modal
        size="xl"
        className="customPopupDesign"
        centered
        toggle={props.Toggle}
        isOpen={props.IsOpen}
      >
        <ModalHeader toggle={props.Toggle}>
          Ticket booking for{" "}
          <strong>{props.Booking.latestVersion.generalInfo.filekey}</strong>
        </ModalHeader>
        <ModalBody>
          <ConfirmTicketCard
            Booking={props.Booking}
            OnUpdate={props.OnUpdate}
            OnSync={props.OnSync}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={props.Toggle}>
            close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

const ConfirmTicketCard: React.FC<{
  Booking: BookingItemModel;
  OnUpdate: (data: BookingItemModel) => void;
  OnSync?: any;
}> = (props) => {
  const booking = props.Booking;
  const [notificationsModalOpen, setNotificationsModalOpen] = useState(false);
  function ToggleNotificationsModal() {
    setNotificationsModalOpen(!notificationsModalOpen);
  }
  const flightDetails1: any = booking.latestVersion;
  const flightDetails: any =
    props.Booking.latestVersion?.flightDetailsInfo == null
      ? flightDetails1?.flightDetailsInfo
      : props.Booking.latestVersion?.flightDetailsInfo;
  const ServiceData: any = flightDetails?.services;
  const notifications = props.Booking.latestVersion.notifications;

  const noPriNotificationsAmount = notifications.filter(
    (e) => e.actionRequired === ENotificationActionRequiredEnumModel.None
  ).length;
  const urgentNotificationsAmount = notifications.filter(
    (e) => e.actionRequired === ENotificationActionRequiredEnumModel.Urgent
  ).length;
  const highPriNotificationsAmount = notifications.filter(
    (e) => e.actionRequired === ENotificationActionRequiredEnumModel.High
  ).length;
  const mediumPriNotificationsAmount = notifications.filter(
    (e) => e.actionRequired === ENotificationActionRequiredEnumModel.Medium
  ).length;
  const lowPriNotificationsAmount = notifications.filter(
    (e) => e.actionRequired === ENotificationActionRequiredEnumModel.Low
  ).length;

  //checkboxSelectionCriteria-Assertion
  const [checkselectedService, setCheckSelectedService] = useState([]);
  const [checkselectedStoredService, setCheckSelectedStoredService] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [isSelected, setSelected] = React.useState<boolean>(false);

  const [ticketBookingBtn, setTicketBookingBtn] = React.useState(false);

  //breakpoints Checker
  const breakpoint = useBootstrap5MediaQuery();

  const initializeCheckD = () => {
    let arr = [];
    if (flightDetails !== null) {
      for (let i = 1; i <= flightDetails?.passengers?.length; i++) {
        arr.push(i);
      }
    }
    return arr;
  };

  const initializeCheckD1 = () => {
    let arr1: any = [];
    if (ServiceData !== null) {
      for (let i = 1; i <= ServiceData?.length; i++) {
        arr1.push(i);
      }
    }
    setSelectionModel1(arr1);
    return arr1;
  };

  const initializeCheckD2 = () => {
    let arr2: any = [];
    if (pnrResponse?.result?.storedServices !== null) {
      // for (let i = 0; i <= pnrResponse?.result?.storedServices.length; i++) {
      // }
      pnrResponse?.result?.storedServices.map((itr, i) => {
        arr2.push(i + 1);
      });
    }
    setSelectionModel2(arr2);
    return arr2;
  };

  const [selectionModel, setSelectionModel] = React.useState<any>(
    initializeCheckD()
  );
  const [selectionModel1, setSelectionModel1] = React.useState<any>([]);
  const [selectionModel2, setSelectionModel2] = React.useState<any[]>([]);

  const bookClient = useBookingClient();
  const [fetching, setFetching] = useState(false);
  const dispatch = useDispatch();
  const [response, setResponse] = useState<UpdatedBookingApiResponse | undefined
  >(undefined);

  const [pnrResponse, setPnrResponse] = useState<GetPNRApiResponse | undefined>(undefined);

  const [error, setError] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const sessionHandler = useSessionHandler();
  const passengerInfo = pnrResponse?.result?.passengers;
  const segmentData = pnrResponse?.result?.segments;
  const segmentIds = segmentData
    ? segmentData.map((segment: any) => segment.segmentNumber)
    : [];
  const [couponData, setCouponData] = useState<any>([]);


  function FetchPNRText() {
    setFetching(true);
    setPnrResponse(undefined);
    bookClient.getPNR(booking.latestVersion.bookingIdentifier).then((response) => {
      dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
      setPnrResponse(response);
      getCouponData(response);
      if (response.result) {
        setError(false);
      } else {
        setError(true);
      }
    }).catch(() => {
      setError(true);
    }).finally(() => {
      setFetching(false);
    });
  }

  useEffect(() => {
    const filteredRows = couponData.filter((row: any) => selectionModel.includes(row.passengerNo));
    const selectedCouponIds = filteredRows.map((row: any) => row.id);
    setSelectionModel2(selectedCouponIds);
    setSelectedRows(filteredRows);
  }, [selectionModel, couponData]);

  useEffect(() => {
    if (pnrResponse?.result?.storedServices?.length) {
      initializeCheckD2();
    }
  }, [pnrResponse?.result?.storedServices]);

  useEffect(() => {
    FetchPNRText();
  }, [booking?.latestVersion?.bookingIdentifier]);

  useEffect(() => {
    if (flightDetails1?.generalInfo?.bookingStatus !== 2) {
      setTicketBookingBtn(true);
    }
  }, [flightDetails1?.generalInfo?.bookingStatus]);



  useEffect(() => {
    if (ServiceData?.length) {
      initializeCheckD1();
    }
  }, [ServiceData]);

  useEffect(() => {
    if (flightDetails?.services?.length) {
      setCheckSelectedService(flightDetails.services);
    }
  }, [flightDetails?.services]);

  useEffect(() => {
    if (
      props.Booking?.latestVersion?.flightDetailsInfo == null &&
      props.OnSync
    ) {
      props.OnSync();
    }
  }, [props.Booking?.latestVersion?.flightDetailsInfo]);

  // submit Ticket
  function SubmitTicket() {
    const identifier = booking.latestVersion?.bookingIdentifier;

    let passengers: any = [];
    selectedRows.map((pas: any) => {
      passengers.push(pas.id);
    });
    var newReq: any = {
      cartId: identifier.cartId,
      bookingId: identifier.bookingId,
      emailIds: {
        to: ["suraj.narule@embarkingonvoyage.com"],
        cc: [""],
        bcc: [""],
      },
      description: "string",
      templateName: "email.html",
      passengerSelection: selectionModel,
      segmentSelection: segmentIds,
      emdSelection: selectedRows.map((emd: any) => (emd.ids)),
    };

    if (identifier) {
      setFetching(true);
      bookClient
        .ticketBooking(newReq)
        .then((response) => {
          setResponse(response);
          dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
          if (response.updatedBooking) {
            setErrorOccurred(false);
            props.OnUpdate(response.updatedBooking);
          } else {
            if (response.validationResult.isValid) {
              setErrorOccurred(true);
            }
          }
        })
        .catch(() => {
          setErrorOccurred(true);
        })
        .finally(() => {
          setFetching(false);
          sessionHandler.RefreshSession();
        });
    }
  }

  const warnings = response?.warnings ? response.warnings : [];
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "passengers", headerName: "Passenger Name", width: 250, flex: 1 },
    { field: "code", headerName: "Code", width: 130, flex: 1 },
    { field: "text", headerName: "Text", width: 320, flex: 1 },
    { field: "carrier", headerName: "Carrier", width: 100, flex: 1 },
    { field: "segments", headerName: "Segments", width: 130, flex: 1 },
  ];

  const columns1LowDevice: GridColDef[] = [
    { field: "id", headerName: "ID", width: 55 },
    { field: "passengerName", headerName: "Passenger Name", width: 90 },
    { field: "filekey", headerName: "Filekey", width: 70 },
    { field: "airline", headerName: "Airline", width: 60 },
    { field: "sourceGDS", headerName: "Source GDS", width: 65 },
    {
      field: "sourceTerminalPCC",
      headerName: "Source Terminal PCC",
      width: 125,
    },
    { field: "statusBooking", headerName: "Status Booking", width: 70 },
    { field: "dateBooking", headerName: "Date Booking", width: 100 },
    {
      field: "dateFirstDeparture",
      headerName: "Date First Departure",
      width: 100,
    },
    { field: "dateTTL", headerName: "Date TTL", width: 95 },
    { field: "dateTicketing", headerName: "Date Ticketing", width: 90 },
    {
      field: "notifications",
      headerName: "Notifications",
      width: 95,
      renderCell: (params: any) => {
        return (
          <span
            style={{
              cursor: "pointer",
              display: "inline-block",
              margin: "0",
            }}
            className="badge rounded-pill bg-warning font-weight-bold"
          >
            {params.value}
          </span>
        );
      },
      sortable: false, // Optional: Disable sorting if not needed
    },
  ];

  const columns1: GridColDef[] = [
    { field: "id", headerName: "ID", width: 55 },
    {
      field: "passengerName",
      headerName: "Passenger Name",
      flex: 1,
      width: 180,
    },
    { field: "filekey", headerName: "Filekey", flex: 1, width: 80 },
    { field: "airline", headerName: "Airline", flex: 1, width: 60 },
    { field: "sourceGDS", headerName: "Source GDS", flex: 1, width: 70 },
    {
      field: "sourceTerminalPCC",
      headerName: "Source Terminal PCC",
      flex: 1,
      width: 120,
    },
    {
      field: "statusBooking",
      headerName: "Status Booking",
      flex: 1,
      width: 70,
    },
    { field: "dateBooking", headerName: "Date Booking", flex: 1, width: 95 },
    {
      field: "dateFirstDeparture",
      headerName: "Date First departure",
      flex: 1,
      width: 95,
    },
    { field: "dateTTL", headerName: "Date TTL", flex: 1, width: 95 },
    {
      field: "dateTicketing",
      headerName: "Date Ticketing",
      flex: 1,
      width: 90,
    },
    {
      field: "notifications",
      headerName: "Notifications",
      flex: 1,
      width: 55,
      renderCell: (params: any) => {
        return (
          <Stack>
            <span
              style={{ cursor: "pointer" }}
              className="badge rounded-pill bg-warning font-weight-bold"
            >
              {params.value}
            </span>
          </Stack>
        );
      },
    },
  ];

  const columns2: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "bookingType", headerName: "Type", flex: 1 },
    { field: "bookingTypeCode", headerName: "Type Code", flex: 1 },
    { field: "bookingTypeID", headerName: "Type ID", flex: 1 },
    { field: "bookingTypeText", headerName: "Type Text", flex: 2 },
    { field: "carrier", headerName: "Carrier", flex: 1 },
    { field: "exchangeable", headerName: "Exchangeable", flex: 1 },
    { field: "extensions", headerName: "Extensions", flex: 2 },
    { field: "lastEMDDate", headerName: "Last EMD Date", flex: 1 },
    { field: "notValidBefore", headerName: "NVB", flex: 1 },
    { field: "notValidAfter", headerName: "NVA", flex: 1 },
    { field: "refundable", headerName: "Refundable", flex: 1 },
    { field: "segmentNumber", headerName: "Segment", flex: 1 },
    { field: "passengerName", headerName: "Passenger Name", flex: 2 },
    { field: "serviceCode", headerName: "Service Code", flex: 1 },
    { field: "serviceText", headerName: "Service Text", flex: 2 },
  ];

  const [rows2, setRows2] = useState<any>([]);
  const rows: any = [];

  const rows1: any = [];
  const getSegment = (data: any) => {
    let ArrData: any = [];
    data.map((itr: any) => {
      return ArrData.push(itr.segments);
    });
    let newArr = _.flatten(ArrData);
    return newArr;
  };

  const getPassengerFullName = (passengerArray:any, number:any) => {
    const matchingPassengers = passengerArray
      .filter((passenger:any) => passenger.passengerNumber === number)
      .map((passenger:any) => `${passenger.firstName} ${passenger.name}`);
      
    return matchingPassengers.join(", ");
  };

  const getCouponData = (pnrResponse: GetPNRApiResponse | undefined) => {
    if (!pnrResponse?.result?.storedServices) return;

    const arr: Array<{
      ids: number;
      id: number;
      bookingType: string;
      bookingTypeCode: string;
      bookingTypeID: string;
      bookingTypeText: string;
      carrier: string;
      exchangeable: string;
      extensions: any; // Adjust type if you know the structure
      lastEMDDate: string;
      notValidAfter: string;
      notValidBefore: string;
      refundable: string;
      segmentNumber: string;
      serviceCode: string;
      serviceText: string;
      passengerNo: number;
    }> = [];

    let count = 1;

    pnrResponse.result.storedServices.forEach((storedService) => {
      storedService.coupons.forEach((coupon: any, index) => {
        const data: any = {
          id: count,
          ids: coupon.id,
          bookingType: coupon.bookingType || '',
          bookingTypeCode: coupon.bookingTypeCode || '',
          bookingTypeID: coupon.bookingTypeID || '',
          bookingTypeText: coupon.bookingTypeText || '',
          carrier: coupon.carrier || '',
          exchangeable: coupon.exchangeable !== undefined ? (coupon.exchangeable ? "Yes" : "No") : '',
          extensions: coupon.extensions,
          lastEMDDate: coupon.lastEMDDate || '',
          notValidAfter: coupon.notValidAfter || '',
          notValidBefore: coupon.notValidBefore || '',
          refundable: coupon.refundable !== undefined ? (coupon.refundable ? "Yes" : "No") : '',
          segmentNumber: coupon.segmentNumber ? coupon.segmentNumber.toString() : '',
          passengerName:getPassengerFullName(pnrResponse?.result?.passengers, storedService.passengerNumber),
          serviceCode: coupon.serviceCode || '',
          serviceText: coupon.serviceText || '',
          passengerNo: storedService.passengerNumber || 0,
        };
        arr.push(data);
        count++;
      });
    });

    setRows2(arr);
    setCouponData(arr);
  };
  passengerInfo?.map((passenger: any, index: any) => {
    let data = {
      id: passenger.passengerNumber,
      passengerName: passenger.firstName + " " + passenger.name,
      filekey: flightDetails1.generalInfo.filekey,
      airline: flightDetails1.generalInfo.platingCarrier.code,
      sourceGDS: flightDetails1.generalInfo.crs.gds,
      sourceTerminalPCC: flightDetails1.generalInfo.crs.terminalPCC,
      statusBooking:
        passenger.ticketNumbers.length > 0
          ? passenger.ticketNumbers[0].ticketStatus
          : GetBookingStatusDisplay(flightDetails1.generalInfo.bookingStatus),
      dateBooking:
        flightDetails1.generalInfo.bookingDate == null
          ? null
          : moment(flightDetails1.generalInfo.bookingDate).format("DD-MM-YYYY"),
      dateFirstDeparture:
        flightDetails1.generalInfo.firstDepartureDate == null
          ? null
          : moment(flightDetails1.generalInfo.firstDepartureDate).format(
            "DD-MM-YYYY"
          ),
      dateTTL:
        flightDetails1.generalInfo.ticketTimeLimit == null
          ? null
          : moment(flightDetails1.generalInfo.ticketTimeLimit).format(
            "DD-MM-YYYY"
          ),
      dateTicketing:
        flightDetails1.generalInfo.ticketingDate == null
          ? null
          : moment(flightDetails1.generalInfo.ticketingDate).format(
            "DD-MM-YYYY"
          ),
      notifications:
        flightDetails1.notifications.length > 0
          ? flightDetails1.notifications.length
          : 0,
    };
    return rows1.push(data);
  });
  //toggleNotificationModal on notification Click.
  const handleOnCellClick = (params: any) => {
    if (params.field == "notifications") {
      ToggleNotificationsModal();
    }
  };
  // map services show on table
  if (checkselectedService !== undefined && flightDetails !== null) {
    checkselectedService.map((itr: any, index: any) => {
      var passengerString = "";
      var segmentString = "";
      let segmentData: any = BookingMangerSegmentsTableData(
        itr.segmentSelection,
        getSegment(flightDetails?.legs)
      );
      let passengerData: any = BookingMangerPassengersTableData(
        itr.passengerSelection,
        flightDetails?.passengers,
        getSegment(flightDetails?.legs)
      );
      if (passengerData.length) {
        passengerString = `${passengerData[0].fName} ${passengerData[0].lName}`;
      }
      if (segmentData.length) {
        segmentString = `${segmentData[0].departureIata}  ->  ${segmentData[0].arrivalIata}`;
      }
      let data = {
        id: index + 1,
        passengers: passengerString,
        code: itr.code,
        text: itr.text,
        carrier: itr.carrier,
        segments: segmentString,
      };
      return rows.push(data);
    });
  }
  const rowGetter = (selectedRows: any) => {
    var serviceArr: any = [];
    var storedServiceArr: any = [];
    selectedRows.map((itr: any) => {
      let passengerData = flightDetails.passengers.filter(
        (el: any) => el.passengerNumber == itr.id
      );
      var selectedService = flightDetails.services.filter(
        (el: any, i: number) =>
          el.passengerSelection[i] == passengerData[i]?.passengerNumber
      );
      var selectedStoredService = pnrResponse?.result?.storedServices.filter(
        (els, i) => els.passengerNumber == passengerData[i]?.passengerNumber
      );
      storedServiceArr = selectedStoredService?.map((itr) => itr.coupons);
      return serviceArr.push(selectedService);
    });
    let newArr: any = _.flatten(serviceArr);
    let newArr1: any = _.flatten(storedServiceArr);
    setCheckSelectedService(newArr);
    setCheckSelectedStoredService(newArr1);
    console.log(newArr, newArr1);

    return;
  };
  useEffect(() => {
    // When the component mounts, select all rows except those with statusBooking "Ticket"
    const filteredRows = rows1.filter((row: any) => row.statusBooking !== "Ticket");
    const allRowIds = filteredRows.map((row: any) => row.id);
    setSelectionModel(allRowIds);
    setSelectedRows(filteredRows);
    setSelected(true);
  }, [rows1?.length]);


  const filterColumnsWithData = (columns: any, rows: any) => {
    return columns.filter((column: any) => {
      // Check if the column has any data in the corresponding field in `rows`
      return rows.some((row: any) => row[column.field] !== undefined && row[column.field] !== null && row[column.field] !== "");
    });
  };

  const bookingStatus = GetBookingStatusDisplay(flightDetails1.generalInfo.bookingStatus);

  pnrResponse?.result?.storedServices.forEach((storedService) => {
    storedService.coupons.forEach((coupon) => {
      console.log(coupon);
    });
  });
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          {fetching && (
            <div className="col-12">
              <WaitingCard />
            </div>
          )}
          {!fetching && (
            <React.Fragment>
              {errorOccurred && (
                <ErrorDisplay data={response?.responseMetaData} />
              )}
              {response?.validationResult && (
                <Fragment>
                  {!response.validationResult.isValid && (
                    <div className="col-12 mb-2">
                      <ValidationErrorDisplay
                        data={response.validationResult}
                      />
                    </div>
                  )}
                </Fragment>
              )}

              {warnings.length > 0 && (
                <div className="col-12 mb-2">
                  <Alert>
                    <h4 className="alert-heading mb-2">
                      <FontAwesomeIcon icon={faExclamationTriangle} /> ATTENTION
                    </h4>
                    <ul>
                      {warnings.map((e, i) => (
                        <li key={"warnings2" + i}>{e}</li>
                      ))}
                    </ul>
                  </Alert>
                </div>
              )}

              {response?.updatedBooking ? (
                <div className="col-12 mb-2">
                  <Alert color="success">Booking successfully ticketed.</Alert>
                </div>
              ) : (
                <div className="col-12 mb-2">
                  <h4>Please confirm to ticket the following booking:</h4>
                </div>
              )}
              {flightDetails !== null && (
                <div>
                  <div className="col-12 mb-2">
                    <Card>
                      <div className="px-4 pt-4">
                        <h4>Passenger:</h4>
                      </div>
                      {flightDetails == null ? (
                        // Render table when flightDetails is null
                        <table className={`table table-sm table-bordered w-100 table-hover bg-white`}>
                          <BookingMangerTableHeader
                            GetArrowIcon={() => <span></span>}
                            SetOrdering={() => { }}
                            ClassName=""
                            ShowActions={false}
                            isFilterApplied={false}
                            syncAll={() => { }}
                          />
                          <tbody>
                            <tr>
                              <BookingMangerTableRowData Booking={props.Booking} />
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        // Render DataGrid when flightDetails is available
                        rows1.length > 0 ? (  // Check if rows1 has rows
                          <DataGrid
                            className="dataGridTable1"
                            rows={rows1}
                            columns={["SM", "XS", "MD"].includes(breakpoint) ? columns1LowDevice : filterColumnsWithData(columns1, rows1)}
                            isRowSelectable={(params) => params.row.statusBooking !== "Ticket"}
                            pageSize={20}
                            checkboxSelection={
                              rows1.some((row: any) => row.statusBooking !== "Ticket") && // Only allow checkbox selection if at least one row is selectable
                              (booking.latestVersion.generalInfo.crs?.gds !== "+W" || flightDetails1.generalInfo.bookingStatus != 2)
                            }
                            disableSelectionOnClick // Optional: Prevent selection on row click if you only want checkbox selection
                            hideFooter
                            autoHeight
                            initialState={{
                              sorting: {
                                sortModel: [{ field: "id", sort: "asc" }],
                              },
                            }}
                            onCellClick={handleOnCellClick}
                            onRowClick={rowGetter}
                            onSelectionModelChange={(ids) => {
                              console.log("ids---", ids);
                              setSelectionModel(ids);
                              const selectedIDs = new Set(ids);
                              // Filter selected rows based on the selected IDs
                              const selectedRows = rows1.filter((row: any) =>
                                selectedIDs.has(row.ids)
                              );
                              setSelectedRows(selectedRows);
                              setSelected(true);
                              rowGetter(selectedRows);
                            }}
                            selectionModel={selectionModel}
                            
                          />

                        ) : (
                          <p>Loading rows...</p>  // Fallback if rows1 is empty
                        )
                      )}

                    </Card>
                  </div>

                  {rows.length > 0 && (

                    flightDetails.services.length > 0 && (
                      <div className="col-12 mb-2">
                        <Card>
                          <div className="px-4 pt-4">
                            <h4>Services:</h4>
                          </div>
                          <CardContent>
                            <div style={{ width: "100%" }}>
                              <DataGrid
                                className="dataGridTable1"
                                rows={rows}
                                key={"2ERow" + rows.id} // Ensure unique key generation
                                columns={filterColumnsWithData(columns, rows)}
                                pageSize={20}
                                checkboxSelection={booking.latestVersion.generalInfo.crs?.gds !== "+W"}
                                selectionModel={selectionModel1}
                                autoHeight
                                hideFooter
                                onCellClick={handleOnCellClick}
                                onRowClick={rowGetter}

                                onSelectionModelChange={(ids) => {
                                  console.log("--------", ids);

                                  setSelectionModel1(ids);
                                  const selectedIDs = new Set(ids);
                                  const selectedRows = rows.filter((row: any) =>
                                    selectedIDs.has(row.id)
                                  );
                                  setSelectedRows(selectedRows);
                                  rowGetter(selectedRows);
                                }}

                              // onSelectionModelChange={(ids) => {

                              //   setSelectionModel1(ids);
                              //   const selectedIDs = new Set(ids);
                              //   // Filter rows based on selected IDs
                              //   const selectedRows = rows.filter((row: any) => selectedIDs.has(row.id));
                              //   setSelectedRows(selectedRows);
                              //   rowGetter(selectedRows);
                              // }}
                              />
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    )

                  )}


                  {rows2.length > 0 && (
                    <div className="col-12 mb-2">
                      <Card>
                        <div className="px-4 pt-4">
                          <h4>Coupons:</h4>
                        </div>
                        <CardContent>
                          <div style={{ width: "100%" }}>
                            <DataGrid
                              className="dataGridTable1"
                              rows={rows2}
                              columns={filterColumnsWithData(columns2, rows2)}
                              checkboxSelection={booking.latestVersion.generalInfo.crs?.gds !== "+W"}
                              pageSize={20}
                              hideFooter
                              autoHeight
                              initialState={{
                                columns: {
                                  columnVisibilityModel: {
                                    // Hide columns status and traderName, the other columns will remain visible
                                    bookingType: false,
                                    extensions:false
                                  },
                                },
                              }}
                              selectionModel={selectionModel2}
                              onCellClick={handleOnCellClick}
                              onSelectionModelChange={(ids) => {
                                console.log(ids);
                                setSelectionModel2(ids);
                                // Create a set of selected IDs
                                const selectedIDs = new Set(ids);
                                // Filter selected rows based on the selected IDs
                                const selectedRows = rows2.filter((row: any) =>
                                  selectedIDs.has(row.id)
                                );


                                setSelectedRows(selectedRows);
                              }}
                            />
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  )}

                </div>
              )}


              {/* {ticketBookingBtn ? <div className="col-12">
                <Button
                  color="success"
                  disabled={true}
                  className="text-white"
                  block
                  onClick={SubmitTicket}
                >
                  <FontAwesomeIcon
                    style={{ color: "white" }}
                    icon={faTicket}
                  />{" "}
                  YES, Ticket EMD
                </Button>{" "}
              </div> :  (
                <div className="col-12">
                  <Button
                    color="success"
                    disabled={ticketBookingBtn}
                    className="text-white"
                    block
                    onClick={SubmitTicket}
                  >
                    <FontAwesomeIcon
                      style={{ color: "white" }}
                      icon={faTicket}
                    />{" "}
                    YES, Ticket booking
                  </Button>{" "}
                </div>
              )} */}


              <div className="col-12">
                {ticketBookingBtn && !rows2.length ? (
                  <Button
                    color="success"
                    disabled={true}
                    className="text-white"
                    block
                    onClick={SubmitTicket}
                  >
                    <FontAwesomeIcon style={{ color: "white" }} icon={faTicket} /> YES, Ticket booking
                  </Button>
                ) : rows2.length ? (
                  <Button
                    color="success"
                    disabled={false}
                    className="text-white"
                    block
                    onClick={SubmitTicket}
                  >
                    <FontAwesomeIcon style={{ color: "white" }} icon={faTicket} /> YES, Ticket EMD
                  </Button>
                ) : (
                  <Button
                    color="success"
                    disabled={ticketBookingBtn}
                    className="text-white"
                    block
                    onClick={SubmitTicket}
                  >
                    <FontAwesomeIcon style={{ color: "white" }} icon={faTicket} /> YES, Ticket booking
                  </Button>
                )}
              </div>

              <BookingManagerManageNotificationModal
                Booking={props.Booking}
                OnUpdate={props.OnUpdate}
                Toggle={ToggleNotificationsModal}
                IsOpen={notificationsModalOpen}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmTicketModal;
